<template>
    <div class="back-button" @click="backButton">
        <img src="@assets/img/icons/left-chevron.svg">
    </div>
</template>

<script>
export default {
    name: "BackButton",
    computed: {
        hasHistory() {
            return window.history.length > 2;
        }
    },
    methods: {
        backButton() {
            this.hasHistory ? this.$router.go(-1) : this.$router.push({ name: "landing" });
        }
    }
}
</script>

<style lang="scss" scoped>
.back-button {
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 10px;
    }
}
</style>
